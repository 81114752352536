
import { MemberCatQuestion } from "@/services/v1/member/cat/types";
import { PropType, defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    allCatQuestion: {
      type: Array as PropType<MemberCatQuestion[]>,
      default: [],
    },
    currentQuestionIdx: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const toggleNavigationQuestion = (): void => {
      cash("#cvRn").toggleClass("transform rotate-180");
      cash("#nsRn").toggleClass("flex-wrap justify-center");
    };

    const updateCurrentQuestionIdx = computed({
      get: () => props.currentQuestionIdx,
      set: (value) => emit("update:current-question-idx", value),
    });

    return {
      toggleNavigationQuestion,
      updateCurrentQuestionIdx,
    };
  },
});
